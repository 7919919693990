module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-178459934-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nicholas Gould","start_url":"/","description":"\nI am a dedicated lifestyle photographer with a keen eye for detail and a passion for storytelling. Inspired by the beauty of the outdoors and fueled by coffee, I am on a mission to capture authentic moments that reflect the art of living and inspire awe. With a deep appreciation for nature and an innate ability to connect with people, I strive to create compelling visuals that resonate with audiences and evoke emotions. Let's collaborate to bring your vision to life through the art of photography.","icon":"src/images/icon.png","include_favicon":true,"background_color":"#0A0909","theme_color":"#EFCB68","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"d7d8182524d5b1f9e706e271121f755c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
